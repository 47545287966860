import { createSlice } from '@reduxjs/toolkit';

const authSlice = createSlice({
    name: 'auth',
    initialState: { token: null as string | null, user: { details: null, isAuthenticated: false, isSSOUser: false } }, // Update the type of state.token
    reducers: {
        setToken: (state, action) => {
            state.token = action.payload;
        },
        setUserDetails: (state, action) => {
            state.user = action.payload;
        }
    },
});

export default authSlice.reducer;
export const { setToken, setUserDetails } = authSlice.actions;