import { useEffect, useRef, useState, useMemo, useCallback, memo } from 'react';
import * as d3 from 'd3';
import { Icon } from '@iconify/react';
import { useParams } from 'react-router-dom';
import { CtyButton } from '../button/CtyButton';
import debounce from 'lodash/debounce';
import { Input, Switch } from '@nextui-org/react';
import CtySpinner from '../spinner/CtySpinner';

interface ExtractedDataProps {
    [key: string]: string;
}

interface ZoomConfig {
    baseScale: number;
    sensitivity: number;
    minScale: number;
    maxScale: number;
}

interface SvgViewerProps {
    svgUrl: string;
    sourceIdData: any;
}

// Default configuration for smooth zooming
const defaultZoomConfig: ZoomConfig = {
    baseScale: 1.2,
    sensitivity: 0.15,
    minScale: 0.1,
    maxScale: Infinity
};

const SvgViewer = memo(({ svgUrl, sourceIdData }: SvgViewerProps) => {
    const svgContainerRef = useRef<HTMLDivElement>(null);
    const svgRef = useRef<SVGSVGElement>(null);
    const zoomRef = useRef<d3.ZoomBehavior<any, any> | null>(null);
    const [selectedColor, setSelectedColor] = useState('');
    const fileName = useParams<{ fileName: string }>();
    const [lineClickEventTriggerIndicator, setLineClickEventTriggerIndicator] = useState(1);
    const [selectedElementMetadata, setSelectedElementMetadata] = useState<ExtractedDataProps | null>(null);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [zoomPercentage, setZoomPercentage] = useState(100);
    const [isMagnifyView, setIsMagnifyView] = useState(false);
    const [isSvgloading, setIsSvgLoading] = useState(false);

    // Memoize wire signals for better performance
    const wireSignals = useMemo(() => {
        return sourceIdData?.[0]?.element_refs[0]?.wire_signals ?? [];
    }, [sourceIdData]);

    // Memoized scale calculation
    const getZoomScale = useCallback((
        direction: 'in' | 'out',
        currentScale: number,
        config: ZoomConfig = defaultZoomConfig
    ) => {
        const scaleFactor = direction === 'in'
            ? 1 + config.sensitivity
            : 1 / (1 + config.sensitivity);
        const newScale = currentScale * scaleFactor;
        return Math.min(Math.max(newScale, config.minScale), config.maxScale);
    }, []);

    // Optimized zoom handler
    const handleZoom = useCallback((direction: 'in' | 'out') => {
        if (!svgRef.current || !zoomRef.current) return;

        const svgElement = d3.select(svgRef.current);
        const zoom = zoomRef.current;
        const currentTransform = d3.zoomTransform(svgElement.node()!);
        const newScale = getZoomScale(direction, currentTransform.k);
        const scaleFactor = newScale / currentTransform.k;

        const bbox = svgElement.node()!.getBoundingClientRect();
        const centerX = bbox.width / 2;
        const centerY = bbox.height / 2;

        const newX = centerX - ((centerX - currentTransform.x) * scaleFactor);
        const newY = centerY - ((centerY - currentTransform.y) * scaleFactor);

        const newTransform = d3.zoomIdentity
            .translate(newX, newY)
            .scale(newScale);

        svgElement
            .transition()
            .duration(200)
            .call(zoom.transform, newTransform);

        setZoomPercentage(Math.round(newScale * 100));
    }, [getZoomScale]);

    // Memoized metadata extraction
    const extractMetadata = useCallback((metadata: string): ExtractedDataProps => {
        const extractedData: ExtractedDataProps = {};
        const regex = /<e3:(.*?)>(.*?)<\/e3:\1>/g;
        Array.from(metadata.matchAll(regex))
            .forEach(([, tag, content]) => {
                extractedData[tag] = content;
            });
        return extractedData;
    }, []);
    const initializeZoom = useCallback((svgElement, wrapper) => {
        // Add zoom and move interaction for the original content
        const zoom = d3.zoom()
            .scaleExtent([0.1, Infinity])
            .on("zoom", (event) => {
                wrapper.attr("transform", event.transform.toString());
                setZoomPercentage(Math.round(event.transform.k * 100));
            });

        zoomRef.current = zoom;
        svgElement.call(zoom);
    }, []);
    const updateMagnifyBehavior = useCallback(() => {
        if (!svgRef.current) return;

        const svgElement = d3.select(svgRef.current);
        const wrapper = svgElement.select('.zoom-wrapper');

        // Clear any existing magnify-related elements
        svgElement.selectAll(".magnify-group, .cursor-border, defs").remove();

        if (isMagnifyView) {
            // Add magnify-related elements dynamically
            const clipPathId = "magnify-clip";
            const rectWidth = 50; // Width of the magnified area
            const rectHeight = 50; // Height of the magnified area
            const borderColor = "black";
            const borderWidth = 1;
            // Add a clip path for the magnified area
            svgElement.append("defs")
                .append("clipPath")
                .attr("id", clipPathId)
                .append("rect")
                .attr("x", 0)
                .attr("y", 0)
                .attr("width", rectWidth)
                .attr("height", rectHeight);

            // Add a magnifying group using the clip path
            const magnifyGroup = svgElement.append("g")
                .attr("class", "magnify-group")
                .attr("clip-path", `url(#${clipPathId})`);

            // Clone only the content elements (excluding background)
            const contentElements = wrapper.selectAll("path, circle, rect, line, text, g:not(.background)").nodes();
            contentElements.forEach((node) => {
                magnifyGroup.node()?.appendChild(node.cloneNode(true));
            });

            // Add border rectangle for the magnified content
            const magnifyBorder = magnifyGroup.append("rect")
                .attr("class", "magnify-border")
                .attr("x", 0)
                .attr("y", 0)
                .attr("width", rectWidth)
                .attr("height", rectHeight)
                .style("fill", "none")
                .style("stroke", borderColor)
                .style("stroke-width", borderWidth);

            // Add cursor tracking rectangle
            const cursorRect = svgElement.append("rect")
                .attr("class", "cursor-border")
                .attr("x", 0)
                .attr("y", 0)
                .attr("width", rectWidth)
                .attr("height", rectHeight)
                .style("fill", "rgba(255, 255, 255, 0.2)") // Semi-transparent background
                .style("stroke", borderColor)
                .style("stroke-width", borderWidth);

            // Mousemove handler for magnifying rectangle
            svgElement.on("mousemove", (event) => {
                const [mouseX, mouseY] = d3.pointer(event);
                const svgBounds = svgRef.current.getBoundingClientRect();

                // Adjust mouseX and mouseY relative to SVG's position
                const adjustedX = mouseX - (svgBounds.left - window.scrollX);
                const adjustedY = mouseY - (svgBounds.top - window.scrollY);

                // Update the position of the clipping rectangle
                svgElement.select(`#${clipPathId} rect`)
                    .attr("x", mouseX - rectWidth / 2)
                    .attr("y", mouseY - rectHeight / 2);

                // Update the position of the cursor rectangle
                cursorRect
                    .attr("x", mouseX - rectWidth / 2)
                    .attr("y", mouseY - rectHeight / 2);

                // Update the position of the magnified rectangle's border
                magnifyBorder
                    .attr("x", mouseX - rectWidth / 2)
                    .attr("y", mouseY - rectHeight / 2);

                // Apply translation and scaling to the magnifyGroup
                const magnificationScale = 15; // Magnification level
                const offsetX = -mouseX * (magnificationScale - 1);
                const offsetY = -mouseY * (magnificationScale - 1);

                magnifyGroup.attr(
                    "transform",
                    `translate(${offsetX}, ${offsetY}) scale(${magnificationScale})`
                );
            });
        } else {
            svgElement.on("mousemove", null); // Remove mousemove handler
        }
    }, [isMagnifyView]);

    useEffect(() => {
        const svgElement = d3.select(svgRef.current);
        const zoom = d3.zoom();
        zoomRef.current = zoom;

        svgElement.call(zoom);

        return () => {
            svgElement.on(".zoom", null); // Remove zoom listeners
        };
    }, [initializeZoom]);

    useEffect(() => {
        updateMagnifyBehavior();
        return () => {
            if (svgRef.current) {
                const svgElement = d3.select(svgRef.current);
                svgElement.on("mousemove", null); // Remove mousemove handler
            }
        };

    }, [isMagnifyView, updateMagnifyBehavior]);

    // Initialize highlighting
    const initializeHighlighting = useCallback((
        wrapper: d3.Selection<any, unknown, null, undefined>
    ) => {
        const wireSignalsSet = new Set(wireSignals);

        const groups = wrapper.selectAll('g')
            .filter(function () {
                const metadataNode = d3.select(this).select('metadata').node();
                if (!metadataNode) return false;
                const wireSignal = metadataNode.innerHTML.match(/<e3:wireSignal>(.*?)<\/e3:wireSignal>/)?.[1];
                return wireSignal && wireSignalsSet.has(wireSignal);
            });
        groups.each(function () {
            const group = d3.select(this);
            const metadataContent = group.select('metadata').node()!.innerHTML;
            const wireColor = metadataContent.match(/<e3:wireColor>(.*?)<\/e3:wireColor>/)?.[1] || '#000';

            group.selectAll('line')
                .classed('highlighted', true)
                .style('stroke-width', 0.5)
                .style('stroke', wireColor);
        });
    }, [wireSignals]);

    // Initialize click handlers
    const handleLineClick = useCallback((
        group: d3.Selection<any, unknown, null, undefined>,
        lines: d3.Selection<any, unknown, null, undefined>,
        event: MouseEvent
    ) => {
        event.stopPropagation();
        setLineClickEventTriggerIndicator(prev => prev + 1);

        const metadata = group.select('metadata').html();

        if (!metadata) return;

        const extractedData = extractMetadata(metadata);
        const isCurrentlyHighlighted = lines.nodes().some(node =>
            d3.select(node).classed('highlighted')
        );

        // Toggle highlight for all lines in the group
        lines
            .classed('highlighted', !isCurrentlyHighlighted)
            .style('stroke', !isCurrentlyHighlighted
                ? (extractedData.wireColor === 'WHITE' ? 'GREY' : extractedData.wireColor)
                : '#0000FF')
            .style('stroke-width', !isCurrentlyHighlighted ? 0.5 : '0.1');

        // Toggle metadata and modal only if highlighting
        if (!isCurrentlyHighlighted) {
            setSelectedElementMetadata(extractedData);
            setIsModalOpen(true);
        } else {
            setSelectedElementMetadata(null);
            setIsModalOpen(false);
        }
    }, [extractMetadata]);

    const initializeClickHandlers = useCallback((
        wrapper: d3.Selection<any, unknown, null, undefined>
    ) => {
        // Extract group selection logic into a separate function
        const selectValidGroups = () => {
            return wrapper.selectAll('g')
                .filter(function () {
                    const group = d3.select(this);
                    const hasMetadata = group.select('metadata').node();
                    const hasLines = group.selectAll('line').size() > 0;
                    return hasMetadata && hasLines;
                });
        };

        // Apply click handlers to selected groups
        const validGroups = selectValidGroups();
        validGroups.each(function () {
            const group = d3.select(this);
            const lines = group.selectAll('line');

            lines
                .style('cursor', 'pointer')
                .on('click', function (event) {
                    handleLineClick(group, lines, event);
                });
        });
    }, [handleLineClick]);
    // Debounced zoom input handler
    const handleZoomInputChange = debounce((zoomValue: string) => {
        const value = parseInt(zoomValue);
        if (isNaN(value) || value < 0 || value > 30000) return;

        if (!svgRef.current || !zoomRef.current) return;

        const svgElement = d3.select(svgRef.current);
        const zoom = zoomRef.current;
        const scale = value / 100;
        const currentTransform = d3.zoomTransform(svgElement.node()!);

        const bbox = svgElement.node()!.getBoundingClientRect();
        const centerX = bbox.width / 2;
        const centerY = bbox.height / 2;

        const newX = centerX - ((centerX - currentTransform.x) * scale / currentTransform.k);
        const newY = centerY - ((centerY - currentTransform.y) * scale / currentTransform.k);

        const newTransform = d3.zoomIdentity
            .translate(newX, newY)
            .scale(scale);

        svgElement.call(zoom.transform, newTransform);
        setZoomPercentage(value);
    }, 150);
    useEffect(() => {
        return () => {
            handleZoomInputChange.cancel(); // Clean up debounced handler
        };
    }, [handleZoomInputChange]);

    // Main SVG loading effect
    useEffect(() => {
        let isMounted = true;
        const loadSvg = async () => {
            try {
                setIsSvgLoading(true);
                const data = await d3.xml(svgUrl);
                if (!svgContainerRef.current) return;

                const fragment = document.createDocumentFragment();
                fragment.appendChild(data.documentElement);

                const svgElement = d3.select(fragment.firstChild as SVGSVGElement);
                const wrapper = svgElement.append('g').attr('class', 'zoom-wrapper');

                // Batch SVG modifications
                svgElement
                    .attr('viewBox', svgElement.attr('viewBox') || '0 0 1000 800')
                    .style('width', '100%')
                    .style('height', '100%');

                // Move content to wrapper
                Array.from(svgElement.node()!.childNodes)
                    .filter(node => node !== wrapper.node())
                    .forEach(node => wrapper.node()!.appendChild(node));

                // Clear and append
                svgContainerRef.current.innerHTML = '';
                svgContainerRef.current.appendChild(fragment);

                svgRef.current = svgElement.node()!;
                // Initialize features
                initializeZoom(svgElement, wrapper);
                initializeHighlighting(wrapper);
                initializeClickHandlers(wrapper);
            } catch (error) {
                console.error('Error loading SVG:', error);
            } finally {
                if (isMounted) setIsSvgLoading(false);// End loading
            }
        };

        loadSvg();

        return () => {
            setZoomPercentage(100);
            setIsModalOpen(false);
            setSelectedElementMetadata(null);
            setLineClickEventTriggerIndicator(1);
            setIsMagnifyView(false);
            setIsSvgLoading(false);
            isMounted = false;
        };
    }, [svgUrl, initializeZoom, initializeHighlighting, initializeClickHandlers]);
    useEffect(() => {
        return () => {
            setZoomPercentage(100);
            setIsModalOpen(false);
            setSelectedElementMetadata(null);
            setLineClickEventTriggerIndicator(1);
            setIsMagnifyView(false);
            setIsSvgLoading(false);

            if (svgRef.current) {
                const svgElement = d3.select(svgRef.current);
                svgElement.on('.zoom', null); // Remove zoom listeners
                svgElement.on("mousemove", null); // Remove mousemove handlers
            }
        };
    }, []);

    // Update stroke colors effect
    useEffect(() => {
        if (!svgRef.current) return;

        const svgElement = d3.select(svgRef.current);
        const wrapper = svgElement.select('.zoom-wrapper');

        if (wrapper.empty()) {
            console.warn('No .zoom-wrapper found in the SVG');
            return;
        }

        const lines = wrapper.selectAll('line'); // Cache lines
        lines.each(function () {
            const line = d3.select(this);
            const isHighlighted = line.classed('highlighted');
            if (isHighlighted && selectedColor) {
                line.style('stroke', selectedColor);
                line.style('stroke-width', 0.5);
            }
        });
    }, [lineClickEventTriggerIndicator, selectedColor]);
    return (
        <div className='flex w-full'>
            <div className="flex flex-col gap-4 w-full relative">
                <div className="flex gap-10 items-center justify-center py-4 border-b-1 border-grey">
                    <div>
                        <h3 className="font-bold text-center">
                            {fileName?.fileName}
                        </h3>
                    </div>

                    <div className='flex gap-4 items-center'>
                        <div className='vertical-line'></div>
                        <p className='flex items-center gap-1 mr-4'>
                            <Icon icon="bx:zoom-in" /> Zoom
                        </p>
                        <div className='flex gap-2 items-center'>
                            <CtyButton
                                onPress={() => handleZoom('out')}
                                className="p-2 bg-gray-100 hover:bg-gray-200 rounded-full min-w-0"
                                aria-label="Zoom Out"
                                title='Zoom Out'
                            >
                                <Icon icon="twemoji:minus" />
                            </CtyButton>
                            <div className="flex items-center gap-2">
                                <Input
                                    type="text"
                                    value={`${zoomPercentage}`}
                                    onChange={(e) => {
                                        handleZoomInputChange(e.target.value);
                                    }}
                                    className="p-1 w-20 text-center"
                                    endContent={'%'}
                                    classNames={
                                        {
                                            inputWrapper: ['w-20'],
                                        }
                                    }
                                />
                            </div>
                            <CtyButton
                                onPress={() => handleZoom('in')}
                                className="p-2 bg-gray-100 hover:bg-gray-200 min-w-0 rounded-full"
                                aria-label="Zoom In"
                                title='Zoom In'
                            >
                                <Icon icon="qlementine-icons:plus-16" />
                            </CtyButton>
                        </div>
                        <div>
                            <CtyButton
                                onPress={() => {
                                    if (svgRef.current && zoomRef.current) {
                                        const svgElement = d3.select(svgRef.current);
                                        const zoom = zoomRef.current;
                                        svgElement.transition()
                                            .duration(200)
                                            .call(zoom.transform, d3.zoomIdentity);
                                        setZoomPercentage(100);
                                    }
                                }}
                                className="p-2 bg-gray-100 hover:bg-gray-200 min-w-0 rounded-full"
                                aria-label="Reset Zoom"
                                title='Reset Zoom'
                            >
                                <Icon icon="system-uicons:reset" />
                            </CtyButton>
                        </div>
                        <div className='vertical-line'></div>
                        <div className="flex items-center gap-2" title='Choose Primary Color'>
                            <label className="text-sm flex cursor-pointer items-center">
                                Primary Color:
                                <input
                                    type="color"
                                    value={selectedColor}
                                    onChange={(e) => setSelectedColor(e.target.value)}
                                    className="ml-2 w-6 h-6 border-0 border-r-0 rounded-full"
                                />
                            </label>
                            <CtyButton
                                onPress={() => setSelectedColor('')}
                                className="p-2 bg-gray-100 hover:bg-gray-200 min-w-0 rounded-full"
                                aria-label="Zoom In"
                                title='Reset Color'
                            >
                                <Icon icon="system-uicons:reset" />
                            </CtyButton>
                        </div>
                        <div className='vertical-line'></div>
                        <Switch onValueChange={setIsMagnifyView} isSelected={isMagnifyView} >
                            <div className='flex items-center justify-center gap-2'>
                                <span>Magnify</span>
                                <Icon icon="fluent-emoji:magnifying-glass-tilted-left" />
                            </div>
                        </Switch>
                    </div>
                </div>
                <div
                    ref={svgContainerRef}
                    style={{
                        width: '100%',
                        height: '85vh',
                        overflow: 'hidden',
                        position: 'relative',
                    }}
                >
                    {isSvgloading ? (
                        <div className="flex items-center justify-center h-full">
                            <CtySpinner size="sm" />
                        </div>
                    ) : <div></div>}
                </div>
                <div className='absolute top-4 right-0'>
                    {selectedElementMetadata && (
                        <SvgMetadataModal
                            metadata={selectedElementMetadata}
                            isOpen={isModalOpen}
                            onClose={() => setIsModalOpen(false)}
                        />
                    )}
                </div>
            </div>
        </div>
    );
});

interface SvgViewerContainerProps {
    svgUrl: string;
    sourceIdData: any;
}

export default function SvgViewerContainer({ svgUrl, sourceIdData }: Readonly<SvgViewerContainerProps>) {
    return (
        <div className='w-full h-[99vh]'>
            <SvgViewer svgUrl={svgUrl} sourceIdData={sourceIdData} />
        </div>
    );
}



interface SvgMetadataModalProps {
    metadata: any;
    isOpen: boolean;
    onClose: () => void;
}

function SvgMetadataModal(props: Readonly<SvgMetadataModalProps>) {
    const { metadata, isOpen, onClose } = props;
    if (!isOpen) return null;

    return (
        <div className="fixed top-4 right-0  flex items-center justify-center">
            <div className="relative bg-white p-6 rounded-lg w-[500px] max-w-[90%] border-1 ">
                <button
                    className="absolute top-2 right-2 text-gray-500 hover:text-gray-700"
                    onClick={onClose}
                >
                    ✕
                </button>
                <h3 className="text-lg font-bold mb-4">Details Info</h3>
                <div>
                    {metadata && Object.keys(metadata).map((key) => (
                        <div key={metadata[key]} className="flex gap-4 border-b border-gray-300 py-2">
                            <p className="w-1/2 font-semibold capitalize">{key}</p>
                            <p className="w-1/2">{metadata[key]}</p>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
}