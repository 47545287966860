import apiEndPoints from "../../apiconstants";
import { api, handleErrorResponse, responseArgs } from "../api";

export interface Report {
    id: string;
    name: string;
    embedUrl: string;
    webUrl: string;
    datasetId: string;
}

export type PbiAccessToken = {
    access_token: string;
    expires_in: number;
}

export type PbiEmbedToken = {
    embed_token: string;
    embed_token_expiration: string;
}
export const analyticsApiSlice = api.injectEndpoints({
    endpoints: builder => ({
        getPbiAccessToken: builder.query<PbiAccessToken, void>({
            query: () => apiEndPoints?.getPbiAccessToken,
            transformResponse: (response: responseArgs) => response.data as PbiAccessToken,
            transformErrorResponse: handleErrorResponse,
        }),
        getPbiEmbedToken: builder.query<PbiEmbedToken, { reportId: string }>({
            query: ({ reportId }) => `${apiEndPoints?.getpbiEmbedToken}/${reportId}`,
            transformResponse: (response: responseArgs) => response.data as PbiEmbedToken,
            transformErrorResponse: handleErrorResponse,
        }),
        getReportsList: builder.query<Report[], void>({
            query: () => `${apiEndPoints?.getReportsList}`,
            transformResponse: (response: responseArgs) => response.data as Report[],
            transformErrorResponse: handleErrorResponse,
        }),
        refreshReportsList: builder.mutation<Report[], { workspaceId: string, datasetId: string }>({
            query: ({ workspaceId, datasetId }) => `https://api.powerbi.com/v1.0/myorg/groups/${workspaceId}/datasets/${datasetId}/refreshes`,
            transformResponse: (response: responseArgs) => response.data as Report[],
            transformErrorResponse: handleErrorResponse,
        }),
        getRefreshAccessToken: builder.query<PbiAccessToken, void>({
            query: () => apiEndPoints?.getRefreshAccessToken,
            transformResponse: (response: responseArgs) => response.data as PbiAccessToken,
            transformErrorResponse: handleErrorResponse,
        }),

    })
});

export const { useGetPbiAccessTokenQuery, useGetPbiEmbedTokenQuery, useGetReportsListQuery,
    useRefreshReportsListMutation, useGetRefreshAccessTokenQuery

} = analyticsApiSlice;