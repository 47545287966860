import React, { useEffect, useRef } from 'react';
import { CtyHeader, getUserInitials, Sidenav } from "@circuitry-ai/doc-ui";
import { useAuth0 } from '@auth0/auth0-react';
import { useDisclosure } from '@nextui-org/react';
import SidebarItems from '../../assets/Sidebaritems';
import { PressEvent } from '@react-types/shared';
import { useLocation } from 'react-router-dom';

type Props = {
  children: React.ReactNode;
};
type CustomTitles = {
  [key: string]: string;
};
const customTitles: CustomTitles = {
  dashboard: "Dashboard",
  advisors: "AIdvisors",
  conversations: "Conversations",
  knowledge: "Knowledge",
  analytics: "AInalysts",
  pdfscanner: "Pdf Scanner",
  ocrscanner: "OCR Scanner",
  automation: "Automations",
  settings: "Settings",
  analyst: "AInalysts",

};
const Layout = ({ children }: Props) => {
  const { user, logout } = useAuth0();
  const userInitals = getUserInitials(user?.nickname);
  const doLogout = () => {
    logout({
      logoutParams: { returnTo: window.location.origin },
    });
  };
  const { isOpen, onOpen, onOpenChange } = useDisclosure();
  const sidebarItems = SidebarItems();
  const pathname = useLocation().pathname;

  const pathnameRef = useRef(pathname);
  useEffect(() => {
    pathnameRef.current = pathname;
  }, [pathname]);
  const currentLocation = pathnameRef.current.split('/')[1] || 'dashboard';
  useEffect(() => {
    document.title = customTitles[currentLocation] || 'Circuitry AI';
  }, []);
  const onSideNavClick = (e: PressEvent) => {
    const currentLocation = e.target.getAttribute('data-key') || 'dashboard';
    document.title = customTitles[currentLocation] || 'Circuitry AI';
    if (currentLocation === "help") {
      let helpUrl = "https://customer.circuitry.ai/knowledge-base";
      const currentPath = pathnameRef.current;
      if (currentPath.includes("/createadvisor")) {
        helpUrl += "/getting-started-with-product-aidvisor";
      } else if (currentPath.includes("/advisors")) {
        helpUrl += "/viewing-and-editing-advisors";
      } else if (currentPath.includes("/conversations")) {
        helpUrl += "/viewing-conversation-history";
      } else if (currentPath.includes("/knowledge")) {
        helpUrl += "/using-the-knowledge-base";
      } else if (currentPath.includes("/settings")) {
        helpUrl += "/settings";
      } else {
        helpUrl += "/dashboard-view";
      }
      window.open(helpUrl, "_blank");
    }
    if (isOpen) {
      onOpenChange();  // This closes the modal
    }
  };
  if (pathname.includes('/file/')) {
    return <div>{children}</div>
  }
  return (
    <div className="flex flex-col h-screen ">
      <nav className="w-full text-white bg-gray-700 fixed top-0 left-0 right-0 z-10">
        <CtyHeader
          logo='/Logo.svg'
          name={userInitals}
          logoutFn={doLogout}
          onOpen={onOpen}
        />
      </nav>
      <div className="flex fixed left-0 top-[67px] bottom-0 w-full z-10">
        <div className='bg-white border-r border-color[#F2F4F7] overflow-y-auto'>
          <Sidenav isOpen={isOpen} onOpenChange={onOpenChange} onClick={onSideNavClick} items={sidebarItems} />
        </div>
        <div className={`flex-1 relative pl-2 sm:h-[calc(100vh-100px)]`}>
          {children}
        </div>
      </div>
    </div>
  );
};

export default Layout;




