import { CtySpinner } from '@circuitry-ai/doc-ui';
import { Suspense, lazy } from 'react';

const AdvisorListLazy = lazy(() => import('@circuitry-ai/doc-viewadvisor').then(module => ({ default: module.AdvisorList }))
  .catch(error => {
    console.error(error);
    return { default: () => <div>{'Error Loading Component'}</div> };
  }));

const DashboardLazy = lazy(() => import('@circuitry-ai/doc-dashboard').then(module => ({ default: module.Dashboard }))
  .catch(error => {
    console.error(error);
    return { default: () => <div>{'Error Loading Component'}</div> };
  }));

const SettingsLazy = lazy(() => import('@circuitry-ai/doc-settings').then(module => ({ default: module.Settings }))
  .catch(error => {
    console.error(error);
    return { default: () => <div>{'Error Loading Component'}</div> };
  }));
const ConversationHistoryLazy = lazy(() => import('@circuitry-ai/doc-conversationhistory').then(module => ({ default: module.ConversationHistory }))
  .catch(error => {
    console.error(error);
    return { default: () => <div>{'Error Loading Component'}</div> };
  }));
const ConversationMessagesLazy = lazy(() => import('@circuitry-ai/doc-conversationhistory').then(module => ({ default: module.ConversationMessages }))
  .catch(error => {
    console.error(error);
    return { default: () => <div>{'Error Loading Component'}</div> };
  }));
const KnowledgeLazy = lazy(() => import('@circuitry-ai/doc-knowledge').then(module => ({ default: module.Knowledge }))
  .catch(error => {
    console.error(error);
    return { default: () => <div>{'Error Loading Component'}</div> };
  }));
const AdvisorKnowledgeLazy = lazy(() => import('@circuitry-ai/doc-knowledge').then(module => ({ default: module.Advisorknowledge }))
  .catch(error => {
    console.error(error);
    return { default: () => <div>{'Error Loading Component'}</div> };
  }));
const AdvisorViewLazy = lazy(() => import('@circuitry-ai/doc-viewadvisor').then(module => ({ default: module.Advisorview }))
  .catch(error => {
    console.error(error);
    return { default: () => <div>{'Error Loading Component'}</div> };
  }));
const AnalyticsDetailsLazy = lazy(() => import('@circuitry-ai/doc-analyst').then(module => ({ default: module.AnalyticsDetails }))
  .catch(error => {
    console.error(error);
    return { default: () => <div>{'Error Loading Component'}</div> };
  }));
const AnalyticsListLazy = lazy(() => import('@circuitry-ai/doc-analyst').then(module => ({ default: module.AnalyticsList }))
  .catch(error => {
    console.error(error);
    return { default: () => <div>{'Error Loading Component'}</div> };
  }));

const AgentsLazy = lazy(() => import('@circuitry-ai/doc-agent').then(module => ({ default: module.Agents }))
  .catch(error => {
    console.error(error);
    return { default: () => <div>{'Error Loading Component'}</div> };
  }));
const AdvisorList = (props: any) => (
  <Suspense fallback={<CtySpinner size='lg' />}>
    <AdvisorListLazy {...props} />
  </Suspense>
);
const AdvisorView = (props: any) => (
  <Suspense fallback={<CtySpinner size='lg' />}>
    <AdvisorViewLazy {...props} />
  </Suspense>
);
const Dashboard = (props: any) => (
  <Suspense fallback={<CtySpinner size='lg' />}>
    <DashboardLazy {...props} />
  </Suspense>
);
const Settings = (props: any) => (
  <Suspense fallback={<CtySpinner size='lg' />}>
    <SettingsLazy {...props} />
  </Suspense>
);
const ConversationHistory = (props: any) => (
  <Suspense fallback={<CtySpinner size='lg' />}>
    <ConversationHistoryLazy {...props} />
  </Suspense>
);
const ConversationMessages = (props: any) => (
  <Suspense fallback={<CtySpinner size='lg' />}>
    <ConversationMessagesLazy {...props} />
  </Suspense>
);
const Knowledge = (props: any) => (
  <Suspense fallback={<CtySpinner size='lg' />}>
    <KnowledgeLazy {...props} />
  </Suspense>
);
const Advisorknowledge = (props: any) => (
  <Suspense fallback={<CtySpinner size='lg' />}>
    <AdvisorKnowledgeLazy {...props} />
  </Suspense>
);
const AnalyticsDetails = (props: any) => (
  <Suspense fallback={<CtySpinner size='lg' />}>
    <AnalyticsDetailsLazy {...props} />
  </Suspense>
);
const AnalyticsList = (props: any) => (
  <Suspense fallback={<CtySpinner size='lg' />}>
    <AnalyticsListLazy {...props} />
  </Suspense>
);

const Agents = (props: any) => (
  <Suspense fallback={<CtySpinner size='lg' />}>
    <AgentsLazy {...props} />
  </Suspense>
);

export { AdvisorList, Dashboard, Settings, ConversationHistory, ConversationMessages, Knowledge, Advisorknowledge, AdvisorView, AnalyticsDetails, AnalyticsList, Agents };