import apiEndPoints from "../../apiconstants";
import { api, handleErrorResponse, responseArgs } from "../api";

export const pdfAnalyzeApiSlice = api.injectEndpoints({
    endpoints: builder => ({
        getPDFUploadPresignedUrl: builder.mutation<string, any>({
            query: (payLoad) => ({
                url: apiEndPoints.getPDFPresignedURL,
                method: 'POST',
                body: payLoad
            }),
            transformResponse: (response: responseArgs) => response.data?.[0],
            transformErrorResponse: handleErrorResponse,
        }), 
        getAgentsJSON: builder.mutation<any, any>({
            query: (payLoad) => ({
                url: apiEndPoints.getAgentsJSON,
                method: 'POST',
                body: payLoad
            }),
            transformResponse: (response: any) => response.data,
            transformErrorResponse: handleErrorResponse
        }),
    })
});

export const { useGetPDFUploadPresignedUrlMutation, useGetAgentsJSONMutation } = pdfAnalyzeApiSlice;